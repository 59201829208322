@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,html {
  text-rendering: optimizeSpeed;
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: "PT Sans", sans-serif;
}

:root {
  --white: #fff;
  --black: #000;

  --grey: #808080;
  --blue: #3FAAF0;

  --red: #ff0000;
}


/* registrations */

.top-space {
  padding: 40px 50px;
  color: var(--white);
  background-color: var(--blue);
  font-size: 2rem;
  font-weight: 600;
}

.reg-subheading {
  text-align: center;
  padding: 50px 0 0 0;
}

.registration-form {
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
  width: 50%;
  min-width: 350px;
  margin: 0 auto;
}

.registration-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 10px;
}
.registration-form label input{
  width: 100%;
  height: 50px;
  text-indent: 10px;
  outline: none;
  border: 1px solid var(--grey);
  border-radius: 7px;
}

.password-space {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: 7px;
  width: 100%;
  padding-right: 20px;
}

.password-space input {
  border: none !important;
}

.registration-form span {
  display: flex;
  gap: 5px;
  width: fit-content;
  margin: 0 0 30px auto;
}
.registration-form span a {
  color: var(--blue);
}


.registration-form button {
  height: 50px;
  border: none;
  outline: none;
  border-radius: 7px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: .5s;
}
.registration-form button:hover {
  transform: scale(.95);
}


@media (max-width: 700px) {
  .top-space {
    padding: 20px;
    font-size: 1.2rem;
  }
}

/* registrations end */









/* navigation bar */

.dashboard-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  height: 60px;
  min-height: 60px;
  background-color: var(--white);
  /* box-shadow: 0 2px 10px 1px rgb(224, 224, 224); */
  position: sticky;
  top: 0px;
  z-index: 10;
}

.dashboard-nav .logo {
  color: var(--blue);
  font-style: italic;
}

.dashboard-nav .logo-pic {
  width: 70px;
  height: fit-content;
  max-width: 70px;
  max-height: fit-content;
}

.dashboard-nav .dn-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
}
.dn-links a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: var(--black);
}
.dn-links a.indicator {
  color: var(--blue) !important;
  font-weight: 600;
}
.dn-links a span{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--white);
  background-color: var(--red);
  border-radius: 50%;
  font-size: .7rem;
  width: 15px;
  height: 15px;
}



@media (max-width: 700px) {

  .dashboard-nav {
    justify-content: center;
    padding: 0px 20px;
    height: 40px;
    min-height: 50px;
  }
  .dashboard-nav .logo {
    text-align: center;
  }

  .dashboard-nav .dn-links {
    display: none;
  }
}


/* navigation bar end */





/* dashboard space */

.dashboard {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

.body {
  display: flex;
  flex-direction: column;
  background-color: var(--blue);
  flex-grow: 1;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 30px 22%;
  background-color: var(--blue);
  color: var(--white);
  height: 140px;
}

.dashboard-header .dh-icons {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 20px;
}

.dh-icons .dhi {
  cursor: pointer;
}


main {
  padding: 50px 0px 100px 0px;
  background-color: var(--white);
  border-radius: 30px 0 0 0;
  height: calc(100vh - 200px);
  overflow-y: auto;
}




@media (max-width: 700px) {

  .dashboard-header {
    padding: 20px 5%;
  }

  main {
    border-radius: 20px 20px 0 0;
    height: calc(100vh - 190px);
  }

}

/* dashboard space end */



/* bottom mobile nav */

.mobile-nav {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  z-index: 100;
  background-color: var(--white);
  box-shadow: 0px -1px 5px 1px rgb(226, 226, 226);
}

.mobile-nav a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--black);
  font-size: 1rem;
  cursor: pointer;
  gap: 5px;
  position: relative;
}
.mobile-nav a span{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;
  font-size: .6rem;
  color: var(--white);
  background-color: var(--red);
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -5px;
}

.mobile-nav a.mn-indicator {
  color: var(--blue);
}


@media (max-width: 700px) {
  .mobile-nav {
    display: flex;
  }
}


/* bottom mobile nav end */



/* dashboard page */


.get-references {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px;
  width: 60%;
  margin: 0px auto 50px auto;
  background-color:#e2f3fe;
  border-radius: 10px;
  color: var(--blue);
}

.get-references span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  gap: 15px;
  width: 350px;
}

.get-ref-icon {
  font-size: 2rem;
}

.get-references a {
  padding: 10px 40px;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--white);
  text-decoration: none;
  font-size: 1.2rem;
  transition: .5s;
}
.get-references a:hover {
  transform: scale(.95);
}


.my-earnings{
  width: 60%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.my-earnings-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-earnings-nav a{
  color: var(--black);
}

.me-subheading span {
  font-size: 1.2rem;
  margin-right: 5px;
  color: var(--grey);
}
.earning-dropdown-btn {
  color: var(--grey);
  cursor: pointer;
}

.my-earnings-description {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background-color: #e7e7e7;
  margin-top: 10px;
  border-radius: 10px;
}
.my-earnings-description span small {
  color: var(--grey);
}

.gotit-btn {
  text-decoration: underline;
  cursor: pointer;
}


.my-earnings-dashboard {
  display: grid;
  grid-template-columns: repeat(1fr,3);
  grid-template-rows: repeat(1fr,3);
  width: 60%;
  height: 250px;
  margin: 0 auto;
  gap: 10px;
  margin-bottom: 60px;
}

.current-earnings {
  grid-area: 1/1/2/3;
}

.my-earnings-dashboard .estimated-earnings:nth-of-type(1) {
  grid-area: 2/1/3/2;
}
.my-earnings-dashboard .estimated-earnings:nth-of-type(2) {
  grid-area: 2/2/3/3;
}

.current-earnings, .estimated-earnings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid var(--blue);
  border-radius: 10px;
  gap: 10px;
}

.current-earnings div:nth-of-type(1) p:nth-of-type(1){
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.earning-amount {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.3rem;
  color: var(--blue);
}


.find-customers {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  width: 50%;
  min-width: 350px;
  text-align: center;
  border: 1px solid var(--blue);
  border-radius: 10px;
  margin: 0 auto;
}

.find-customers .fc-icon {
  font-size: 2rem;
  color: var(--blue);
  margin-bottom: 10px;
}

.find-customers a {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  color: var(--white);
  background-color: var(--blue);
  padding: 20px 30px;
  border-radius: 10px;
  text-decoration: none;
  margin-top: 20px;
  transition: .5s;
  border: 2px solid transparent;
}
.find-customers a:hover {
  transform: scale(.97);
  background-color: var(--white);
  border-color: var(--blue);
  color: var(--blue);
}



@media (max-width: 850px) {

  .my-earnings-dashboard {
    width: 90%;
  }

  .my-earnings {
    width: 90%;
  }

  .get-references {
    width: 90%;
  }
}

@media (max-width: 450px) {

  .get-references span {
    width: 300px;
    font-size: 1rem;
  }

  .my-earnings-dashboard .estimated-earnings{
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

}



/* dashboard page end */


/* references page */

#ref-nav {
  box-shadow: none;
}

.references-main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  background-color: var(--blue);
}

.ref-header {
  text-align: center;
  padding: 55px 20px 15px 20px;
  height: 100px;
  color: var(--white);
  font-size: 1.5rem;
}

.references-space {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  padding: 40px 20px 100px 20px;
  border-radius: 20px 20px 0 0;
  background-color: var(--white);
}

.rs-subheading, .rs-text, .rs-sub-text {
  text-align: center;
  line-height: 1.6;
}

.rs-subheading {
  font-weight: 500;
}

.rs-icon {
  font-size: 5rem;
  margin: 30px auto;
}

.rs-text {
  width: 60%;
  margin-bottom: 10px;
}

.rs-link {
  display: flex;
  margin: 20px 0;
  width: 350px;
  height: 60px;
}

.rs-link span:nth-of-type(1) {
  display: flex;
  align-items: center;
  padding: 0 15px;
  width: 80%;
  max-width: 80%;
  overflow-x: auto;
  border-radius: 10px 0 0 10px;
  border: 1px dashed var(--black);
  border-right: none;
}

.rs-link span:nth-of-type(1)::-webkit-scrollbar {
  display: none;
}

.rs-link span:nth-of-type(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 0 10px 10px 0;
  min-width: fit-content;
  cursor: pointer;
  position: relative;
}

.rs-link span:nth-of-type(2) .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--blue);
  color: var(--white);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 10;
}


@media (max-width: 750px) {

  .rs-subheading {
    font-size: 1.4rem;
  }
  
  .rs-icon {
    font-size: 3.5rem;
  }

  .rs-text {
    width: 100%;

  }

}

/* references page end */




/* Notifications page */

.nd-nav {
  box-shadow: 0 2px 5px 1px rgb(208, 208, 208);
}

.notification-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notification-space {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
  margin: 50px auto;
}

.notifications {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  box-shadow:  0 0  5px 1px rgb(207, 207, 207);
  background-color: var(--white);
  gap: 20px;
  text-decoration: none;
  color: var(--black);
}

.notification div {
  max-width: 80%;
}

.notification div small {
  color: var(--grey);
}


@media (max-width: 700px) {

  .nd-nav {
    justify-content: left;
  }

  .notification-space {
    width: 90%;
  }

}

/* Notifications page */





/* offers page */

.offers-space {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  padding: 50px 0 80px 0;
}

.offers-subheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding: 0 20px;
  margin-bottom: 20px;
}

.offers-tab-heading {
  display: flex;
  background-color: var(--white);
  width: 50%;
  gap: 10px;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 0 15px 1px #d7d7d7;
}

.offers-tab-heading div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
  width: 50%;
  border-radius: 10px;
  cursor: pointer;
}
.offers-tab-heading div span{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: .5rem;
  color: var(--white);
  background-color: var(--red);
  border-radius: 50%;
  cursor: pointer;
}

.oth-active {
  box-shadow: 0 0 5px 1px #d7d7d7;
}


.cleaning-offers {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgb(217, 216, 216);
  width: 50%;
  margin: 30px 0 50px 0;
  padding: 10px 0 0 0;
  gap: 10px;
}

.offer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 0 5px 1px #d7d7d7;
  width: 49%;
  text-decoration: none;
  color: var(--black);
}

.offer .offer-side1 .offer-duration {
  color: var(--grey);
  font-size: .9rem;
  margin: 5px 0 15px 0;
}
.offer .offer-side1 span {
  display: flex;
  align-items: center;
  gap: 5px;
}
.offer .offer-side1 span p:nth-of-type(1){
  color: var(--blue);
  font-size: 1rem;
}


.no-offers {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
}

.no-offer-icon {
  font-size: 2rem;
  color: var(--blue);
  margin: 20px 0 30px 0;
}



@media (max-width: 1200px) {
  .offer {
    width: 100%;
  }
}


@media (max-width: 750px) {

  .offers-subheading, .offers-tab-heading, .cleaning-offers, .no-offers {
    width: 90%;
  }


  .offers-space {
    padding-bottom: 100px;
  }
}

/* offers page */



/* cleaning offers page */

.co-page {
  position: absolute;
  width: 100%;
  top: 0px;
}

.cleaning-offers-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 50px 10px 50px;
  height: 100px;
  background-color: var(--blue);
  color: var(--white);
  position: sticky;
  top: 40px;
}
.cleaning-offers-header a {
  font-size: 1.5rem;
  color: var(--white);
}


.offer-description {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  border-radius: 15px;
  background-color: #c1e6ff;
  width: 50%;
  margin: 0px auto 30px auto;
}

.offer-description section {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 20px 10px;
  border-bottom: 1px solid #e8f5fd;
}
.offer-description section:last-of-type {
  border-bottom: 0px solid #e8f5fd;
}
.offer-description section a{
  color: var(--black);
  text-decoration: none;
  margin: 0 0 0 auto;
}

.offer-countdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  margin: 0 auto 30px auto;
  box-shadow: 0 0 5px 1px rgb(232, 232, 232);
}

.offer-countdown span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
}

.offer-countdown span .oc-icon  {
  padding: 10px;
  border-radius: 5px;
  background-color: #dcf1ff;
  font-size: 1.5rem;
  margin-right: 10px;
}

.ocm-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0px;
  background-color: rgba(112, 109, 109, 0.391);
  backdrop-filter: blur(5px);
  z-index: 100000;
}

.offer-countdown-modal {
  padding: 20px 50px 50px 50px;
  border-radius: 10px;
  width: 50%;
  background-color: var(--white);
  text-align: center;
}

.ocm-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
}

.offer-countdown-modal .oc-icon {
  display: block;
  padding: 10px;
  border-radius: 5px;
  background-color: #dcf1ff;
  font-size: 1.5rem;
  margin: 20px auto;
}

.offer-countdown-modal small{
  display: block;
  color: var(--grey);
  margin: 30px 0 20px 0;
}

.offer-countdown-modal h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.offer-countdown-modal ul li {
  text-align: left; 
  color: var(--grey);
  font-weight: 100;
  margin-bottom: 10px;
  font-size: .9rem;
}


.offer-booking-space {
  width: 50%;
  margin: 0 auto 50px auto;
}
.offer-booking-space h3 {
  margin-bottom: 20px;
}


.booking-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.booking-details section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid rgb(226, 226, 226);
}

.booking-payment-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #daedfa;
  padding: 20px;
  border-radius: 0 0 10px 10px;
}
.booking-payment-section div{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.booking-payment-section div p{
  font-size: .9rem;
}
.booking-payment-section div small{
  color: var(--grey);
}


.offer-availability {
  display: flex;
  align-items: center;
  gap: 40px;
  width: 50%;
  margin: 30px auto;
  font-size: 1.2rem;
}

.offer-availability span {
  display: block;
  width: 50%;
  height: 2px;
  background-color: var(--grey);
}
.offer-availability p{
  min-width: fit-content;
  color: var(--grey);
}


.availability-btns {
  display: flex;
  gap: 30px;
  width: 40%;
  margin: 40px  auto 50px auto;
}
.availability-btns span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47%;
  height: 45px;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: var(--blue);
  color: var(--white);
  transition: .5s;
}
.availability-btns span:hover {
  transform: scale(.95);
}
.availability-btns span:nth-of-type(2){
  background-color: var(--white);
  color: var(--blue);
  border: 2px solid var(--blue);
}


@media (max-width: 800px) {

  .cleaning-offers-header {
    padding: 20px 20px 10px 20px;
  }

  .offer-description, .offer-countdown, .offer-availability, .availability-btns, .offer-booking-space {
    width: 90%;
  }

  .offer-countdown-modal {
    width: 90%;
    padding: 20px 30px 50px 30px;
  }
}


/* cleaning offers page end */



/* Calendar space */

.calendar-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.calendar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 20px 20px;
  background-color: #c8e5f8;
}

.calendar-nav a {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: #333;
}

.month-navigation {
  display: flex;
  align-items: center;
  gap: 50px;
}

.month-navigation span {
  margin: 0 10px;
}

.calendar-space {
  flex: 1;
  padding: 20px;
  background-color: var(--white);
}

.calendar-header {
  margin-bottom: 30px;
  text-align: center;
}

.calendar-month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.day {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 5px 1px #e6e6e6;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.day.selected {
  background-color: var(--blue);
  color: white;
}

.calendar-day {
  margin-top: 20px;
}

.calendar-day h3 {
  margin-bottom: 10px;
}

.appointment {
  background-color: var(--blue);
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

@media (min-width: 600px) {
  .calendar-month {
    grid-template-columns: repeat(7, 1fr);
  }
}

/* calendar end */





/* more */

.more-body {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  background-color: var(--blue);
}

.more-header {
  display: flex;
  align-items: center;
  padding: 80px 50px 20px 50px;
  gap: 30px;
}

.more-header a {
  color: var(--black);
  font-size: 1.3rem;
}

.more-header .profile-display {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2rem;
}

.more-header figure {
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 50%;
}
.more-header figure img{
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
}

.more-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  border-radius: 20px 10px 0 0;
  padding: 50px 20px 100px 20px;
  background-color: var(--white);
  overflow-y: auto;
}

.ms-section {
  width: 60%;
  /* background-color: red; */
  margin-bottom: 30px;
}

.ms-section .ms-section-subheading {
  margin-bottom: 10px;
}

.ms-section div {
  display: flex;
  flex-direction: column;
}

.ms-section div section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid rgb(226, 226, 226);
  margin-bottom: 5px;
}
.ms-section div section small{
  color: var(--grey);
}


.cl-wrapper, .da-wrapper, .el-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0px;
  background-color: rgba(112, 109, 109, 0.391);
  backdrop-filter: blur(5px);
  z-index: 100000;
}

.change-language-modal, .deactivate-account-modal, .edit-location-modal {
  display: flex;
  flex-direction: column;
  padding: 20px 50px 50px 50px;
  border-radius: 10px;
  width: 50%;
  background-color: var(--white);
  text-align: center;
}

.cl-cancel, .da-cancel, .el-cancel {
  display: block;
  margin: 0 0 0 auto;
  cursor: pointer;
  font-size: 1.4rem;
}

.change-language-modal h2 {
  margin: 20px 0 10px 0;
}

.change-language-modal small {
  color: var(--grey);
}

.change-language-modal div {
  padding: 0 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgb(227, 227, 227);
  width: 100%;
  margin: 30px 0;
}
.change-language-modal select {
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
  padding: 15px 0;
}

.deactivate-account-modal h1 {
  font-weight: 400;
  margin: 20px 0 40px 0 ;
}

.da-download-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  color: var(--blue);
  border-radius: 50%;
  background-color: #d9f0ff;
  margin: 0 auto 10px auto;
}

.deactivate-account-modal p {
  width: 80%;
  color: var(--grey);
  text-align: center;
  margin: 0 auto 50px auto;
}

.da-btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: fit-content;
  margin: 0 auto;
}

.da-btns span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 60px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s;
}
.da-btns span:hover {
  transform: scale(.98);
}
.da-btns span:nth-of-type(2) {
  background-color: transparent;
  color: var(--blue);
  border: 2px solid var(--blue);
}
.da-btns span:nth-of-type(1) {
  color: var(--white);
  background-color: var(--blue);
}

.edit-location-modal h2 {
  margin-bottom: 30px;
}

.edit-location-modal form div{
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  height: fit-content;
  padding: 5px 10px 0 10px;
  border-radius: 5px;
  border: 1px solid var(--blue);
  background-color: #e0eef7;
  margin-bottom: 20px;
}
.edit-location-modal form div small{
  display: block;
  text-align: left;
  font-size: .7rem;
}
.edit-location-modal form div input{
  display: block;
  outline: none;
  border: none;
  height: 25px;
  width: 100%;
  background-color: transparent;
}

.edit-location-modal button {
  border: none;
  outline: none;
  padding: 20px 30px;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  color: var(--white);
  background-color: var(--blue);
  transition: .5s;
  cursor: pointer;
}
.edit-location-modal button:hover {
  transform: scale(.98);
}


.logout-btn {
  color: var(--black);
  text-decoration: none;
  padding: 10px 40px;
  border-radius: 10px;
  border: 2px solid var(--black);
  font-size: 1.2rem;
  margin: 30px 0;
  transition: .5s;
}
.logout-btn:hover  {
  transform: scale(.97);
}





@media (max-width: 700px) {
  
  .more-body {
    height: calc(100vh - 40px);
  }

  .more-header {
    display: flex;
    align-items: center;
    padding: 80px 20px 20px 20px;
    gap: 30px;
  }

  .ms-section {
    width: 100%;
  }

  .change-language-modal, .deactivate-account-modal, .edit-location-modal{
    width: 90%;
  }

  .deactivate-account-modal h1 {
    font-size: 1.3rem;
  }
  .deactivate-account-modal p {
    width: 100%;
  }
}

/* more end */


/* edit profile  */

.edit-account-space {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.edit-account-space h1 {
  margin-bottom: 20px;
}

.edit-account-space div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #d7d6d6;
}
.edit-account-space div span{
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.ea-btn {
  padding: 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: var(--blue);
  color: var(--white);
  margin-top: 30px;
  transition: .5s;
}
.edit-account-space .ea-btn:hover {
  transform: scale(.95);
}


.email-wrapper, .mobile-number-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0px;
  background-color: rgba(112, 109, 109, 0.391);
  backdrop-filter: blur(5px);
  z-index: 100000;
}

.email-modal, .mobile-number-modal {
  display: flex;
  flex-direction: column;
  padding: 20px 50px 50px 50px;
  border-radius: 10px;
  width: 50%;
  background-color: var(--white);
  text-align: center;
}

.mn-cancel, .mail-cancel {
  display: block;
  margin: 0 0 0 auto;
  cursor: pointer;
  font-size: 1.4rem;
}

.email-modal form div, .mobile-number-modal form div {
  display: flex;
  flex-direction: column;
  padding: 20px 50px 50px 50px;
  border-radius: 10px;
  width: 50%;
  background-color: var(--white);
  text-align: center;
}


.email-modal form div, .mobile-number-modal form div{
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  height: fit-content;
  padding: 5px 10px 0 10px;
  border-radius: 5px;
  border: 1px solid var(--blue);
  background-color: #e0eef7;
  margin-bottom: 20px;
  margin-top: 20px;
}
.email-modal form div small, .mobile-number-modal form div small{
  display: block;
  text-align: left;
  font-size: .7rem;
}
.email-modal form div input, .mobile-number-modal form div input{
  display: block;
  outline: none;
  border: none;
  height: 25px;
  width: 100%;
  background-color: transparent;
}

.email-modal button, .mobile-number-modal button {
  border: none;
  outline: none;
  padding: 10px 30px;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  color: var(--white);
  background-color: var(--blue);
  transition: .5s;
  cursor: pointer;
}
.email-modal button:hover, .mobile-number-modal button:hover {
  transform: scale(.98);
}



@media (max-width: 700px) {

  .edit-account-space {
    width: 100%;
  }
  
  .email-modal, .mobile-number-modal {
    width: 90%;
  }
}

/* edit profile end */




/* help page */

.help-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}


.help-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
  height: 60px;
  box-shadow: 0 2px 5px 1px #ececec;
}

.help-nav .hn-back {
  cursor: pointer;
  font-size: 1.5rem;
}

.help-space{
  display: flex;
  justify-content: center;
  height: calc(100vh - 60px);
  padding: 50px 10px 100px 10px;
  /* background-color: red; */
  overflow-y: auto;
  margin: 0 auto;
}
.help-space .hs-wrapper {
  width: 50%;
}

.hs-space {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  box-shadow: 0 0 5px 1px #ececec;
  border-radius: 40px;
  margin-top: 10px;
}

.hs-space input {
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
}

.hs-space span {
  min-width: fit-content;
  padding: 10px;
  cursor: pointer;
}


.accordion-space {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0 0 0;
}

.accordion {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(211, 209, 209);
  border-radius: 5px;
}

.accordion-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
}

.accordion-content {
  background-color: #e0f3ff;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}

.accordion-content.open {
  max-height: 200px; /* Adjust based on content */
  padding: 20px;
}



@media (max-width: 700px) {

  .help-nav {
    padding: 0 20px;
  }

  .help-space .hs-wrapper{
    width: 100%;
  }
  
}


/* help page end */




/* customers space */

.customers-space {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  /* background-color: red; */
  margin: 0 auto;
}

.cs-tab-btns {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 50px;
  width: fit-content;
  gap: 5px;
  margin-bottom: 30px;
}

.cs-tab-btns span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  width: 150px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
}

.cs-tab-indicator {
  background-color: var(--white);
  box-shadow: 0 0 3px 0px grey;
}
.cs-tab-indicator:hover {
  transform: scale(.99);
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
  width: 100%;
}


.customer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgb(219, 219, 219);
  width: 100%;
  margin-bottom: 20px;
}

.customer-details p:nth-of-type(1) {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.customer-details p:nth-of-type(2) {
  font-size: .8rem;
  color: var(--grey);
}

.no-customer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}



@media (max-width:700px) {
  .customers-space {
    width: 100%;
  }
}

/* customers space end */



/* set availability page*/

.availability-space {
  width: 50%;
}

.availability-space h1 {
  text-align: center;
  font-weight: 400;
  font-size: 1.6rem;
  font-style: italic;
}

.as-header {
  margin: 20px 0 20px 0;
}

.as-tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.as-tab-header span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: var(--black);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.as-tab-header span.active {
  color: var(--white);
  background-color: var(--black);
}

.day-tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.day-tab-header div{
  display: flex;
  /* justify-content: ; */
  align-items: center;
  width: 40px;
  height: 10px;
  background-color: #c1e6ff;
  border-radius: 20px;
}
.day-tab-header div span{
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--blue);
  border-radius: 50%;
  transition: .5s;
}

.day-tab-header div.off-btn {
  background-color: #d7d7d7;
}
.day-tab-header div.off-btn span {
  background-color: var(--grey);
  transform: translateX(100%);
}

.atc-time-span {
  margin: 30px 0 50px 0;
  padding: 0 0 0 20px;
}


.atc-buttons span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
  border-radius: 5px;
  cursor: pointer;
}
.atc-buttons span:nth-of-type(1) {
  background-color: var(--blue);
  margin-bottom: 10px;
}
.atc-buttons span:nth-of-type(2) {
  background-color: var(--red);
}

@media (max-width: 700px) {
  
  .availability-space {
    width: 100%;
  }

  .as-tab-header span {
    transform: scale(.9);
  }

}

/* set availability page end*/






/* Timeslider */

.time-slider-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-indicators {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.time-labels {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.warning-message {
  margin-top: 10px;
  color: red;
  font-size: 14px;
  text-align: center;
}

.appointment {
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px 0;
}

.appointment.execution {
  background-color: #f0ad4e; /* Example color for execution */
}

.appointment.completed {
  background-color: #5cb85c; /* Example color for completed */
}

.appointment.other-status {
  background-color: #d9534f; /* Example color for other statuses */
}

.day.selected {
  background-color: #337ab7; /* Example selected day color */
  color: white;
}
.day.has-appointments {
  border: 2px solid #007bff; /* Change this color as needed */
}

.appointment.execution {
  background-color: #f0ad4e; /* Change this color as needed */
}

.appointment.completed {
  background-color: #5cb85c; /* Change this color as needed */
}

.existing-slots {
  margin-top: 10px;
}

.slot {
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 10px;
  display: inline-block;
}

.add-another-slot {
  color: #10b981;
  cursor: pointer;
  display: block;
  margin-top: 5px;
  text-decoration: none;
}


/* Timeslider end */